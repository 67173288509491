<!--
 * @Author: 李洋
 * @Date: 2021-07-02 15:20:00
 * @LastEditors: 李洋
 * @LastEditTime: 2021-07-02 17:40:49
 * @Description: 客户损益
-->
<template>
  <div class="custom-container">
    <div class="table-container">
      <div class="title">北大荒完达山乳业2021年6月客户别损益分析报表</div>
      <vxe-table border align="center" :data="tableData">
        <vxe-table-column field="a" title="销售部" align="left"></vxe-table-column>
        <vxe-table-column field="a1" title="大区" align="left"></vxe-table-column>
        <vxe-table-column field="a2" title="城市" align="left"></vxe-table-column>
        <vxe-table-column field="a3" title="客户" align="left"></vxe-table-column>
        <vxe-table-colgroup title="收入">
          <vxe-table-column field="b" title="计划"></vxe-table-column>
          <vxe-table-column field="c" title="实际"></vxe-table-column>
        </vxe-table-colgroup>
        <vxe-table-colgroup title="成本">
          <vxe-table-column field="d" title="计划"></vxe-table-column>
          <vxe-table-column field="e" title="实际"></vxe-table-column>
        </vxe-table-colgroup>
        <vxe-table-colgroup title="营销费用">
          <vxe-table-column field="f" title="计划"></vxe-table-column>
          <vxe-table-column field="g" title="实际"></vxe-table-column>
        </vxe-table-colgroup>
        <vxe-table-colgroup title="利润">
          <vxe-table-column field="h" title="计划"></vxe-table-column>
          <vxe-table-column field="i" title="实际"></vxe-table-column>
        </vxe-table-colgroup>
        <vxe-table-colgroup title="利润率">
          <vxe-table-column field="j" title="计划"></vxe-table-column>
          <vxe-table-column field="k" title="实际"></vxe-table-column>
        </vxe-table-colgroup>
      </vxe-table>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableData: [
        {
          a: '奶粉营销事业部', a1: '黑龙江省区', a2: '哈尔滨', a3: '哈尔滨宜佳达商贸有限公司', b: '200000.00', c: '203034.00', d: '70000.00', e: '73150.00', f: '42000.00', g: '41325.00', h: '88000.00', i: '88559.00', j: '44.00', k: '43.62',
        },
        {
          a: '奶粉营销事业部', a1: '黑龙江省区', a2: '哈尔滨', a3: '哈尔滨市道外区新益康食品批发部', b: '500000.00', c: '503034.00', d: '175000.00', e: '182875.00', f: '105000.00', g: '104325.00', h: '220000.00', i: '215834.00', j: '44.00', k: '42.91',
        },
        {
          a: '奶粉营销事业部', a1: '黑龙江省区', a2: '哈尔滨', a3: '哈尔滨市道里区红孩子母婴用品商行', b: '1500000.00', c: '1503034.00', d: '525000.00', e: '548625.00', f: '525000.00', g: '315000.00', h: '314325.00', i: '660000.00', j: '44.00', k: '42.59',
        },
        {
          a: '奶粉营销事业部', a1: '黑龙江省区', a2: '哈尔滨', a3: '哈尔滨市客户0001', b: '400000.00', c: '403034.00', d: '140000.00', e: '140000.00', f: '84000.00', g: '83325.00', h: '176000.00', i: '173409.00', j: '44.00', k: '43.03',
        },
        {
          a: '奶粉营销事业部', a1: '黑龙江省区', a2: '哈尔滨', a3: '哈尔滨市客户0002', b: '600000.00', c: '603034.00', d: '210000.00', e: '219450.00', f: '126000.00', g: '125325.00', h: '264000.00', i: '258259.00', j: '44.00', k: '42.83',
        },
        {
          a: '奶粉营销事业部', a1: '黑龙江省区', a2: '哈尔滨', a3: '哈尔滨市客户0003', b: '378700.00', c: '381734.00', d: '132545.00', e: '138509.00', f: '79527.00', g: '78852.00', h: '166628.00', i: '164372.00', j: '44.00', k: '43.06',
        },
        {
          a: '奶粉营销事业部', a1: '黑龙江省区', a2: '哈尔滨', a3: '哈尔滨市客户0004', b: '2700000.00', c: '2703034.00', d: '945000.00', e: '987525.00', f: '567000.00', g: '1188000.00', h: '500000.00', i: '1149184.00', j: '44.00', k: '42.51',
        },
        {
          a: '奶粉营销事业部', a1: '黑龙江省区', a2: '哈尔滨', a3: '哈尔滨市客户0005', b: '450000.00', c: '453034.00', d: '157500.00', e: '164587.00', f: '94500.00', g: '93825.00', h: '198000.00', i: '194621.00', j: '44.00', k: '42.96',
        },
        {
          a: '奶粉营销事业部', a1: '黑龙江省区', a2: '哈尔滨', a3: '哈尔滨市客户0006', b: '200000.00', c: '203034.00', d: '70000.00', e: '73150.00', f: '42000.00', g: '41325.00', h: '88000.00', i: '88559.00', j: '44.00', k: '43.62',
        },
        {
          a: '奶粉营销事业部', a1: '黑龙江省区', a2: '哈尔滨', a3: '哈尔滨市客户0007', b: '100000.00', c: '103034.00', d: '35000.00', e: '36575.00', f: '21000.00', g: '20325.00', h: '44000.00', i: '46134.00', j: '44.00', k: '44.78',
        },

      ],
    };
  },
};
</script>
<style lang="less" scoped>
::-webkit-scrollbar {
  height: 0;
  width: 0;
  color: transparent;
}
.custom-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.title {
  font-size: 20px;
  text-align: center;
  color: #333333;
  font-weight: bold;
  padding: 10px 0;
}
.echarts-container {
  display: flex;
  align-items: stretch;
}
.map-container,
.bar-container {
  flex: 1;
  height: 400px;
}
</style>
